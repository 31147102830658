import React from 'react';

import Contact from '../../../components/contact';
import Hero from '../../../components/hero';
import Layout from '@src/components/layout';

import * as styles from './savings.module.css';

import information_till_insattare from '../../../../static/pdfs/erik_penser_bank_information_till_insattare.pdf';

const InformationTillInsattare = () => {
  return (
    <Layout newStyle>
      <Hero
        title="Rocker Spar information till insättare"
        subtitleMarkdown="Klicka på länkarna nedan för att ladda ner"
      />
      <div className="container">
        <div
          style={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <a
            className={styles.link}
            href={information_till_insattare}
            target="blank"
          >
            Information till insättare
          </a>
        </div>
      </div>
      <Contact />
    </Layout>
  );
};

export default InformationTillInsattare;
